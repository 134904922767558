import React from 'react'
import './styles.css'
import Footer from '../../components/Footer'
import { Helmet } from 'react-helmet-async'
import LegalThumbnail from '../../Images/thumbnail_legal.png'

function TermsAndConditions() {
  return (
    <div className='app light center'>
      <Helmet>
        {/* Metadata */}
        <title>InkPal - Terms and Conditions</title>
        <meta property="og:description" content={"Ink Pal App - Terms and Conditions"} />

        {/* Other Metadata */}
        <meta property="og:image" content={LegalThumbnail} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="400" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.inkpalbooks.com/terms-conditions" />

        <link rel="canonical" href="https://www.inkpalbooks.com/terms-conditions" />
      </Helmet>
      <div className='box'>
        <h1>Términos y Condiciones</h1>
        <p>Términos y Condiciones de InkPal</p>
        <p>Última actualización: 31-05-2024</p>
        <br />
        <p>Bienvenido a InkPal. Estos Términos y Condiciones rigen el uso de nuestra aplicación de gestión de lectura. Al registrarte y usar InkPal, aceptas estos términos.</p>
        <br />
        <h3>1. Descripción del Servicio</h3>
        <p>InkPal es una aplicación que permite a los usuarios buscar libros, ver su información, añadirlos a su lista de lectura, actualizar el progreso de lectura, cargar PDF de forma local, valorar libros mediante plantillas, añadir amigos, enviar libros a amigos, ver perfiles públicos, y obtener logros.</p>
        <br />
        <h3>2. Requisitos de Edad y Restricciones Geográficas</h3>
        <p>Para usar InkPal, debes tener al menos 13 años de edad y, si eres menor de edad, debes contar con el consentimiento de tus padres o tutores. No hay restricciones geográficas para el uso de InkPal.</p>
        <br />
        <h3>3. Registro y Cuentas</h3>
        <p>Para registrarte en InkPal, debes proporcionar tu correo electrónico, nombre de usuario, contraseña, fecha de nacimiento y preferencias de lectura. Con tu cuenta, puedes:</p>
        <ul>
          <li>Crear, editar, y borrar tu cuenta.</li>
          <li>Cambiar tu contraseña y subir una imagen de perfil personalizada.</li>
          <li>Poner tu perfil en privado y cerrar sesión.</li>
        </ul>
        <br />
        <h3>4. Conducta del Usuario</h3>
        <p>Está prohibido:</p>
        <ul>
          <li>Usar bots o crear múltiples cuentas.</li>
          <li>Subir imágenes de perfil que sean +18 o irrespetuosas.</li>
        </ul>
        <p>Las violaciones de estas normas pueden resultar en el bloqueo o borrado de la cuenta.</p>
        <br />
        <h3>5. Derechos de Propiedad Intelectual</h3>
        <p>InkPal retiene todos los derechos sobre el contenido y la propiedad intelectual de la aplicación. Los usuarios pueden subir imágenes de perfil, pero InkPal no retiene derechos sobre estas imágenes.</p>
        <br />
        <h3>6. Limitaciones de Responsabilidad</h3>
        <p>InkPal no se hace responsable por la pérdida de cuentas o cualquier daño derivado del uso de la aplicación.</p>
        <br />
        <h3>7. Modificaciones de los Términos y Condiciones</h3>
        <p>InkPal se reserva el derecho de modificar estos términos en cualquier momento. Los cambios se notificarán en la aplicación con la antelación mínima estipulada por la ley en Europa.</p>
        <br />
        <h3>8. Contacto</h3>
        <p>Para cualquier consulta, contáctanos en inkpalbooks@gmail.com.</p>
      </div>
      <Footer />
    </div>
  )
}

export default TermsAndConditions