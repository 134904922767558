import React from 'react'
import './styles.css'
import Footer from '../../components/Footer'
import { Helmet } from 'react-helmet-async'
import LegalThumbnail from '../../Images/thumbnail_legal.png'

function DataPrivacy() {
  return (
    <div className='app light center'>
      <Helmet>
        {/* Metadata */}
        <title>InkPal - Data Privacy</title>
        <meta property="og:description" content={"Ink Pal App - Data Privacy"} />

        {/* Other Metadata */}
        <meta property="og:image" content={LegalThumbnail} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="400" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.inkpalbooks.com/data-privacy" />

        <link rel="canonical" href="https://www.inkpalbooks.com/data-privacy" />
      </Helmet>
      <div className='box'>
        <h1>Privacidad de Datos</h1>
        <p>Política de Privacidad de Datos de InkPal</p>
        <p>Última actualización: 31-05-2024</p>
        <br />
        <p>Esta política detalla cómo procesamos tus datos personales conforme al GDPR.</p>
        <br />
        <h3>1. Base Legal para el Procesamiento de Datos</h3>
        <p>Procesamos tus datos personales con tu consentimiento, que proporcionas al crear una cuenta en InkPal y aceptar nuestros términos y políticas.</p>
        <br />
        <h3>2. Propósitos del Procesamiento</h3>
        <p>Usamos tus datos para mejorar tu experiencia de usuario con mejores recomendaciones y, si es necesario, enviar contenido promocional de la app por correo electrónico.</p>
        <br />
        <h3>3. Transferencias Internacionales de Datos</h3>
        <p>No transferimos tus datos fuera de la UE.</p>
        <br />
        <h3>4. Retención de Datos</h3>
        <p>Retenemos tus datos hasta que decidas borrar tu cuenta.</p>
        <br />
        <h3>5. Derechos de los Usuarios</h3>
        <p>Puedes ejercer tus derechos de acceso, rectificación y eliminación de datos desde la aplicación.</p>
        <br />
      </div>
      <Footer />
    </div>
  )
}

export default DataPrivacy