import React from 'react'
import { Helmet } from 'react-helmet-async'

function Seo({...props}) {

  return (
    <Helmet>
      {/* Metadata */}
      <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta property="og:title" content={props.ogTitle} />
        <meta property="og:description" content={props.ogDescription} />

        {/* Other Metadata */}
        <meta property="og:image" content={props.thumbnail} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="400" />
        <meta property="og:type" content={props.type} />
        <meta property="og:url" content={props.url} />

        <link rel="canonical" href={props.url} />
    </Helmet>
  )
}

export default Seo