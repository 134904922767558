import React, { useEffect, useState } from 'react'
import Light from '../Icons/light-icon.svg';
import Dark from '../Icons/moon-icon.svg';
//import i18next from 'i18next';

import Down from '../Icons/down-arrow-icon.svg';
import Up from '../Icons/up-arrow-icon.svg';

import Logo from '../Images/Logo.webp'
import hamburger from '../Icons/hamburger-icon.png'

function Header({theme, changeTheme, home, contact, blog, download, lang}) {

  //const [lang, setLang] = useState("ES");
  const [langSelectorOpen, setLangSelectorOpen] = useState(false);
  const [showMobileDrop, setShowMobileDrop] = useState(false);

  /*const handleChangeLanguage = async (event) => {
    await i18next.changeLanguage(event);
    localStorage.setItem("language", event);
    setLang(event);
  };*/

  useEffect(() => {
    const currentTheme = localStorage.getItem("theme");
    
    if (currentTheme) {
      changeTheme(currentTheme);
    } else {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      if (mediaQuery.matches) {
        changeTheme("dark");
      }
    }
  });

  const handleChangeTheme = () => {
    changeTheme(theme === "light" ? "dark" : "light");
    localStorage.setItem("theme", theme === "light" ? "dark" : "light");
  }


  const scrollToSection = (e) => {
    e.preventDefault();
    setShowMobileDrop(false);

    const sectionId = e.target.getAttribute('href');
    const section = document.querySelector(sectionId);

    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header>
      <div id="main-menu">
        <div id="page-theme-box" onClick={handleChangeTheme}>
          {
            theme === 'light' ?
            <img src={Light} alt="Light" id="theme-icon" width={25} height={25}/>
            :
            <img src={Dark} alt="Dark" id="theme-icon" width={25} height={25}/>
          }
        </div>
        <div id="header-box">
          <ul>
            <li><a href='#home' onClick={scrollToSection}>{home}</a></li>
            <li><a href='#contact' onClick={scrollToSection}>{contact}</a></li>
            <li><a href={`${lang === "es" ? "/es/blog" : "/blog"}`}>{blog}</a></li>
            <li><a href='https://play.google.com/store/apps/details?id=com.KronyK.InkPalBooks' target='_blank' rel="nofollow noreferrer noopener">{download}</a></li>
          </ul>
        </div>
        <div id="lang-selector-box" onClick={() => setLangSelectorOpen(!langSelectorOpen)}>
        <div className="dropdown-header">
          {lang.toLocaleUpperCase()}
          <span className="dropdown-arrow">{langSelectorOpen ? <img src={Up} alt="UP" width={15} height={15} /> : <img src={Down} alt="DOWN" width={15} height={15} />}</span>
        </div>
        {langSelectorOpen && (
          <ul className="dropdown-list">
            <li><a className="dropdown-list-item" href='/es' hrefLang='es' rel="alternate">ES</a></li>
            <li><a className="dropdown-list-item" href='/' hrefLang='en' rel="alternate">EN</a></li>
          </ul>
        )}
        </div>
      </div>
      <div id="mobile-main-menu">
        <div className='hamburger'>
          <img src={hamburger} alt="Abrir menu" width={40} height={40} onClick={() => setShowMobileDrop(!showMobileDrop)}/>
          {showMobileDrop && 
          <div className='hamburger-menu'>
            <ul>
              <li><a href='#home' onClick={scrollToSection}>{home}</a></li>
              <li><a href='#contact' onClick={scrollToSection}>{contact}</a></li>
              <li><a href='/blog'>{blog}</a></li>
              <li><a href='https://play.google.com/store/apps/details?id=com.KronyK.InkPalBooks' target='_blank' rel="nofollow noreferrer noopener">{download}</a></li>
              <li onClick={() => [handleChangeTheme(), setShowMobileDrop(false)]}>
              {
                theme === 'light' ?
                <img src={Light} alt="Light" id="theme-icon" width={25} height={25}/>
                :
                <img src={Dark} alt="Dark" id="theme-icon" width={25} height={25}/>
              }
              </li>
            </ul>
          </div>}
        </div>
        <img src={Logo} alt="Ink Pal Logo" width={50} height={50}/>
        <div className='row'>
          <a href='/es' hrefLang='es' rel="alternate" className={lang === "es" ? "active" : ""}>ES</a>
          <a href='/' hrefLang='en' rel="alternate" className={lang === "en" ? "active" : ""}>EN</a>
        </div>
      </div>
    </header>
  )
}

export default Header