import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Seo from '../components/Seo';
import thumbnail from '../Images/thumbnail.png'
import MainFooter from '../components/MainFooter';
import CookiePopUp from '../components/CookiePopUp';
 // https://i.imgur.com/25SwTNr.png
function PageTemplate({...props}) {

  const [theme, setTheme] = useState('light');

  useEffect(() => {
    document.getElementById('html-tag').setAttribute('lang', props.lang);
  },[props.lang]);

  useEffect(() => {
    document.body.classList.remove('light', 'dark');
    document.body.classList.add(theme);

    return () => {
      document.body.classList.remove('light', 'dark');
    };
  }, [theme]);

  return (
    <div className="app">
      <Seo 
      title={props.title}
      description={props.description}
      ogTitle={props.ogTitle}
      ogDescription={props.ogDescription}
      type={props.type}
      url={props.url}
      thumbnail={thumbnail}/>
      <Header 
      theme={theme} 
      changeTheme={setTheme}
      home={props.headerHome}
      contact={props.headerContact}
      blog={props.headerBlog}
      download={props.headerDownload}
      lang={props.lang}/>
      {props.children}
      <MainFooter 
      homeName={props.headerHome}
      homeUrl={props.relativeUrl}
      termsName={props.termsName}
      privacityName={props.privacityName}
      dataName={props.dataName}
      />
      <CookiePopUp />
    </div>
  )
}

export default PageTemplate