import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Importa las dependencias necesarias
import './index.css';
import Friends from './Pages/Friends';
import HomePageEs from './Pages/ES/HomePageEs';
import HomePage from './Pages/EN/HomePage';
import DataPrivacy from './Pages/Legal/DataPrivacy';
import PrivacyPolicies from './Pages/Legal/PrivacyPolicies';
import TermsAndConditions from './Pages/Legal/TermsAndConditions';
import { HelmetProvider } from 'react-helmet-async';
//import i18n from 'i18next';
//import { I18nextProvider, initReactI18next } from 'react-i18next';
//import NotFound from './Pages/NotFound';
import CookiePolicies from './Pages/Legal/CookiePolicies';
import Groups from './Pages/Groups';

/*const enTranslation = require('./language/en.json');
const esTranslation = require('./language/es.json');

i18n
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    lng: 'en',
    fallbackLng: 'en',
    resources: {
      en: {
        translation: enTranslation.translation,
      },
      es: {
        translation: esTranslation.translation,
      },
    },
  });

const savedLanguage = localStorage.getItem('language');
if (savedLanguage) {
  i18n.changeLanguage(savedLanguage);
} else {
  const userLanguage = navigator.language || navigator.userLanguage;
  if (userLanguage.startsWith('es')) {
    i18n.changeLanguage('es');
  } else {
    i18n.changeLanguage('en');
  }
}*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <Router> 
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/es" element={<HomePageEs />} />
        <Route path="/friends" element={<Friends />} />
        <Route path="/groups" element={<Groups />} />
        <Route path="/data-privacy" element={<DataPrivacy />} />
        <Route path="/privacy-policies" element={<PrivacyPolicies />} />
        <Route path="/terms-conditions" element={<TermsAndConditions/>} />
        <Route path="/cookie-policies" element={<CookiePolicies/>} />
      </Routes>
    </Router>
  </HelmetProvider>
);