import React from 'react'
import Arrow from '../../Icons/arrow_icon.svg';

import ScreenShot from '../../Images/PrevScreenShots.webp';
import ScreenShotRatingES from '../../Images/Valoraciones_ES.webp';
import ScreenShotGestionES from '../../Images/Gestion_ES.webp';
import ScreenShotFriendsES from '../../Images/Friends_ES.webp';
import ScreenShotRecomendacionES from '../../Images/Recomendacion_ES.webp';

import MainImage from '../../Images/HandWithApp.webp'
import MainImageLower from '../../Images/HandWithApp_lower.webp'
import PageTemplate from '../PageTemplate';

function HomePage() {

  return (
    <PageTemplate 
    lang="en" 
    title="InkPal - Reading Management" 
    description="Discover InkPal, the best app to manage your readings with a modern interface. Rate your books with custom templates, track your progress, and connect with friends. Join the reading community!"
    ogTitle="InkPal - The Best Reading Management and Rating App" 
    ogDescription="InkPal: Your Reading Management and Rating App"
    type="website"
    url="https://www.inkpalbooks.com/en"
    headerHome="Home"
    headerContact="Contact"
    headerBlog="Blog"
    headerDownload="Download app"
    relativeUrl="/en"
    termsName="Terms and Conditions"
    privacityName="Privacy Policies"
    dataName="Data Privacy"
    >
      <div className='radial top'></div>
      <a className='download-app' href='https://play.google.com/store/apps/details?id=com.KronyK.InkPalBooks' target='_blank' rel="nofollow noreferrer noopener">
        <div>
          <p className='download-app-name'>InkPal</p>
          <p>Download now</p>
        </div>
        <img src={Arrow} alt="Arrow" width={25} height={25} />
      </a>
      <div className="home" id="home">
        <h1>InkPal: Your Reading Management and Rating App</h1>
        <p>Explore a modern and user-friendly platform to manage all your readings.
        Rate your books with custom templates and share your opinions with friends.</p>
        <picture>
          <source srcSet={MainImageLower} media="(max-width: 768px)" type="image/webp" />
          <source srcSet={MainImage} media="(min-width: 769px)" type="image/webp" />
          <img 
            src={MainImage} 
            alt="Inkpal home page" 
            title="InkPal, readings management"
            loading="lazy" 
            className="main-image"
          />
        </picture>
        <div className='radial bottom'></div>
      </div>
      <div className='full-height-darker content'>
        <h2>Modern and Attractive Interface</h2>
        <p>Discover the beauty of our interface, designed to make your reading management experience more pleasant and efficient. Explore our intuitive and customizable features to fully enjoy your reading moments.</p>
        <div className='floating-info'>
          <h3>Intuitive Design</h3>
          <p> - </p>
          <h3>Visual Customization</h3>
        </div>
          <img src={ScreenShot} title='InkPal home page' alt="InkPal app books" loading='lazy' width={877} height={702}/>
      </div>
      <div className='full-height-lighter content'>
        <h2>Personalized Ratings with Templates</h2>
        <p>Create unique and detailed ratings for each book with our custom templates. Express your opinions in a complete and organized manner, and share your reviews with other readers. Transform your reading experience into an even more enriching adventure.</p>
        <div className='floating-info'>
          <h3>Select Your Favorite Templates</h3>
          <p> - </p>
          <h3>Save and Share Your Ratings</h3>
        </div>
          <img src={ScreenShotRatingES} title='Rate books with templates' alt="Rate books templates" loading='lazy' width={877} height={702}/>
      </div>
      <div className='full-height-darker content'>
        <h2>Efficient Reading Management</h2>
        <p>Organize your reading library efficiently and easily with our advanced management tools. Track your reading progress, add notes and tags, and keep a complete record of your favorite books. Simplify your reading life with InkPal.</p>
        <div className='floating-info'>
          <h3>Add and Organize Your Books</h3>
          <p> - </p>
          <h3>Update Your Reading Progress</h3>
        </div>
          <img src={ScreenShotGestionES} title='Manage bookshell app' alt="Reading manager app" loading='lazy' width={877} height={702}/>
      </div>
      <div className='full-height-lighter content'>
        <h2>Connect with Your Friends</h2>
        <p>Explore the social dimension of reading by connecting with friends and sharing your literary experiences. Discover new titles recommended by your contacts, see what the people you follow are reading, and enjoy a community passionate about books.</p>
        <div className='floating-info'>
          <h3>Search and Add Friends</h3>
          <p> - </p>
          <h3>Share Reading Recommendations</h3>
        </div>
          <img src={ScreenShotFriendsES} title='Share books with friends' alt="Reading app friends" loading='lazy' width={877} height={702}/>
      </div>
      <div className='full-height-darker content'>
        <h2>Personalized Recommendations</h2>
        <p>Be surprised by our personalized recommendations, based on your reading tastes and preferences. Our smart algorithms will help you discover new books that match your interests, thus expanding your literary horizon. Dive into a world of fascinating reads with InkPal.</p>
        <div className='floating-info'>
          <h3>Intelligent Recommendation Algorithms</h3>
          <p> - </p>
          <h3>Explore New Reads</h3>
        </div>
          <img src={ScreenShotRecomendacionES} title='Recomended books to read' alt="AI book recommendation" loading='lazy' width={877} height={702}/>
      </div>
      <div id="contact">
        <p className='contact-title'>Contact Us</p>
        <p className='contact-subtitle'>Do you have questions, requests, or found any errors? Don't hesitate to contact us and let us know.</p>
        <p className='email'>support@inkpalbooks.com</p>
        <a href='mailto:support@inkpalbooks.com' target='_blank' rel="nofollow noreferrer noopener">
          SEND EMAIL
        </a>
      </div>
    </PageTemplate>
  )
}

export default HomePage