import React from 'react';
import './styles.css';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet-async';
import LegalThumbnail from '../../Images/thumbnail_legal.png';

function PrivacyPolicies() {
  return (
    <div className='app light center'>
      <Helmet>
        {/* Metadata */}
        <title>InkPal - Privacy Policies</title>
        <meta property="og:description" content={"Ink Pal App - Privacy Policies"} />

        {/* Other Metadata */}
        <meta property="og:image" content={LegalThumbnail} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="400" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.inkpalbooks.com/privacy-policies" />

        <link rel="canonical" href="https://www.inkpalbooks.com/privacy-policies" />
      </Helmet>
      <div className='box'>
        <h1>Política de Privacidad</h1>
        <p>Política de Privacidad de InkPal</p>
        <p>Última actualización: 31-05-2024</p>
        <br />
        <p>En InkPal, valoramos tu privacidad. Esta política describe cómo recopilamos, usamos y protegemos tus datos personales tanto en nuestra aplicación como en nuestra web.</p>
        <br />
        <h3>1. Datos Personales Recopilados</h3>
        <p>Recopilamos la siguiente información:</p>
        <ul>
          <li>En la aplicación: Correo electrónico, nombre de usuario, contraseña, fecha de nacimiento, intereses de lectura, y la IP del usuario.</li>
          <li>En la web: Correo electrónico para el newsletter.</li>
        </ul>
        <br />
        <h3>2. Uso de Datos</h3>
        <p>Usamos tus datos para los siguientes propósitos:</p>
        <ul>
          <li>En la aplicación: Mejorar las recomendaciones de contenido y mostrar publicidad no personalizada dentro de la aplicación. No compartimos tus datos con terceros, excepto con Google AdMob, y solo solicitamos anuncios no personalizados.</li>
          <li>En la web: Enviarte correos de novedades, lanzamientos y otras actualizaciones a través de nuestro newsletter, para el cual debes aceptar recibir correos al proporcionar tu dirección de correo electrónico.</li>
        </ul>
        <br />
        <h3>3. Seguridad de los Datos</h3>
        <p>Tus contraseñas están encriptadas en nuestra base de datos para proteger tu información personal.</p>
        <br />
        <h3>4. Uso de Cookies</h3>
        <p>Nuestra web utiliza cookies para mejorar tu experiencia y para analizar el uso del sitio web a través de Google Analytics. Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo. Utilizamos estas cookies para recopilar información sobre cómo los visitantes usan nuestro sitio, lo que nos ayuda a mejorar su funcionalidad y contenido.</p>
        <p>Las cookies en nuestra web se utilizan de la siguiente manera:</p>
        <ul>
          <li>Google Analytics: Para analizar el tráfico de la web y entender cómo los usuarios interactúan con ella.</li>
        </ul>
        <p>Al visitar nuestra web, se te pedirá que aceptes o rechaces el uso de cookies. Puedes gestionar tus preferencias de cookies en cualquier momento desde la configuración de tu navegador.</p>
        <br />
        <h3>5. Derechos de los Usuarios</h3>
        <p>Puedes acceder, corregir o eliminar tus datos desde los ajustes de la aplicación. Para retirar tu consentimiento en la aplicación, envíanos un correo electrónico a support@inkpalbooks.com. Esto inhabilitará tu cuenta.</p>
        <p>Para el newsletter de la web, puedes darte de baja en cualquier momento haciendo clic en el enlace de baja que se encuentra en el pie de cada correo electrónico que enviamos.</p>
        <br />
        <h3>6. Contacto</h3>
        <p>Para cualquier consulta relacionada con la privacidad, contáctanos en support@inkpalbooks.com.</p>
        <br />
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicies;
