import React, { useEffect, useState } from 'react';

function CookiePopUp() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const acceptedCookies = localStorage.getItem('acceptedCookies');
    if (!acceptedCookies) {
      setShowPopup(true);
    } else if (acceptedCookies === 'true') {
      injectAnalytics();
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('acceptedCookies', 'true');
    setShowPopup(false);
    injectAnalytics();
  };

  const handleRejectCookies = () => {
    localStorage.setItem('acceptedCookies', 'false');
    setShowPopup(false);
  };

  const injectAnalytics = () => {
    const script1 = document.createElement('script');
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-K40SFDW3MQ";
    script1.async = true;
    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-K40SFDW3MQ');
    `;
    document.body.appendChild(script2);
  };
  return (
    showPopup && (
      <div className='cookie-banner'>
        <p>
          Utilizamos cookies para mejorar su experiencia y analizar el uso del sitio web. Al aceptar, consiente el uso de cookies según nuestra <a href="/privacy-policy" rel="nofollow">Política de Privacidad</a>. Puede rechazar las cookies si lo desea.
        </p>
        <div>
          <button onClick={handleAcceptCookies} className='cookie-button'>Aceptar</button>
          <button onClick={handleRejectCookies} className='cookie-button'>Rechazar</button>
        </div>
      </div>
    )
  );
  
};

export default CookiePopUp;
