import React from 'react'

function Footer() {
  return (
    <footer>
      <ul>
        <li><a href='/terms-conditions' rel='nofollow'>Terms and conditions</a></li>
        <li><a href='/privacy-policies' rel='nofollow'>Privacy Policies</a></li>
        <li><a href='/data-privacy' rel='nofollow'>Data Privacy</a></li>
        <li><a href='/cookie-policies' rel='nofollow'>Cookie Policies</a></li>
      </ul>
      <div>
        <p>&copy; InkPal - 2024</p>
      </div>
    </footer>
  )
}

export default Footer