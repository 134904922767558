import React from 'react'
import IG from '../Icons/IG_icon.png'
import TikTok from '../Icons/TikTok_icon.png'

import GooglePlay from '../Icons/InkPalGooglePlay_icon.png'

function MainFooter({...props}) {
  return (
  <footer className='main-footer'>
    <div className='footer-row'>
      <div className='footer-column'>
        <p className='footer-column-title'>InkPal</p>
        <ul>
          <li><a href={`${props.homeUrl}`}>{props.homeName}</a></li>
          <li><a href="https://www.inkpalbooks.com/terms-conditions" rel='nofollow'>{props.termsName}</a></li>
          <li><a href="https://www.inkpalbooks.com/privacity-policies" rel='nofollow'>{props.privacityName}</a></li>
          <li><a href="https://www.inkpalbooks.com/data-privacy" rel='nofollow'>{props.dataName}</a></li>
        </ul>
      </div>
      <div className='footer-column icons flex-center'>
        <a href='https://instagram.com/' aria-label='InkPal Instagram' target='_blank' rel="nofollow noreferrer noopener"><picture><img src={IG} alt='Instagram InkPal' title='Instagram InkPal' width={40} height={40} /></picture></a>
        <a href='https://instagram.com/' aria-label='TikTok Instagram' target='_blank' rel="nofollow noreferrer noopener"><picture><img src={TikTok} alt='TikTok InkPal' title='TikTok InkPal' width={40} height={40} /></picture></a>
      </div>
      <div className='footer-column right'>
        <a href='https://play.google.com/store/apps/details?id=com.KronyK.InkPalBooks' target='_blank' rel="nofollow noreferrer noopener"><picture><img src={GooglePlay} alt="InkPal Google Play" title='InkPal Google Play' width={170} height={54}/></picture></a>
      </div>
    </div>
    <div>
      <p>&copy; InkPal - 2024</p>
    </div>
  </footer>
  )
}

export default MainFooter