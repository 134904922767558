import React from 'react'
import './styles.css'
import Footer from '../../components/Footer'
import { Helmet } from 'react-helmet-async'
import LegalThumbnail from '../../Images/thumbnail_legal.png'

function CookiePolicies() {
  return (
    <div className='app light center'>
      <Helmet>
        {/* Metadata */}
        <title>InkPal - Cookies Policies</title>
        <meta property="og:description" content={"Ink Pal App - Cookies Policies"} />

        {/* Other Metadata */}
        <meta property="og:image" content={LegalThumbnail} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="400" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.inkpalbooks.com/cookie-policies" />

        <link rel="canonical" href="https://www.inkpalbooks.com/cookie-policies" />
      </Helmet>
    <div className='box'>
      <h1>Política de Cookies</h1>
      <p>Política de Almacenamiento de Datos de InkPal</p>
      <p>Última actualización: 31-05-2024</p>
      <br />
      <p>InkPal utiliza tecnologías similares a las cookies para mejorar tu experiencia de usuario.</p>
      <br />
      <h3>1. Uso de Tecnologías Similares</h3>
      <p>Usamos Async Storage para autenticar usuarios, almacenar ajustes y preferencias, y mejorar la rapidez de acceso a los datos.</p>
      <br />
      <h3>2. Propósitos</h3>
      <p>Estas tecnologías se usan para la autenticación de usuarios, almacenamiento de ajustes, y preferencias del usuario.</p>
      <br />
      <h3>3. Gestión de Preferenciass</h3>
      <p>Puedes eliminar tu cuenta y todos los datos asociados desde la aplicación en cualquier momento.</p>
    </div>
    <Footer />
  </div>
  )
}

export default CookiePolicies