import React from 'react'
import Arrow from '../../Icons/arrow_icon.svg';

import ScreenShot from '../../Images/PrevScreenShots.webp';
import ScreenShotRatingES from '../../Images/Valoraciones_ES.webp';
import ScreenShotGestionES from '../../Images/Gestion_ES.webp';
import ScreenShotFriendsES from '../../Images/Friends_ES.webp';
import ScreenShotRecomendacionES from '../../Images/Recomendacion_ES.webp';

import MainImage from '../../Images/HandWithApp.webp'
import MainImageLower from '../../Images/HandWithApp_lower.webp'
import PageTemplate from '../PageTemplate';

function HomePageEs() {

  return (
    <PageTemplate
    title="InkPal - Gestión de Lecturas" 
    description="Descubre InkPal, la mejor app para gestionar tus lecturas con una interfaz moderna. Valora tus libros con plantillas personalizadas, sigue tu progreso y conecta con amigos. ¡Únete a la comunidad de lectores!"
    ogTitle="InkPal - La Mejor App de Gestión y Valoración de Lecturas" 
    ogDescription="InkPal: Tu App de Gestión y Valoración de Lecturas"
    type="website" 
    url="https://www.inkpalbooks.com/es"
    headerHome="Inicio"
    headerContact="Contacto"
    headerBlog="Blog"
    headerDownload="Descargar app" 
    lang="es"
    relativeUrl="/es"
    termsName="Terminos y Condiciones"
    privacityName="Politicas de Privacidad"
    dataName="Privacidad de Datos"
    >
      <div className='radial top'></div>
      <a className='download-app' href='https://play.google.com/store/apps/details?id=com.KronyK.InkPalBooks' target='_blank' rel="nofollow noreferrer noopener">
        <div>
          <p className='download-app-name'>InkPal</p>
          <p>Descargar ahora</p>
        </div>
        <img src={Arrow} alt="Arrow" width={25} height={25} />
      </a>
      <div className="home" id="home">
        <h1>InkPal: Tu App de Gestión y Valoración de Lecturas</h1>
        <p>Explora una plataforma moderna y fácil de usar para gestionar todas tus lecturas. 
        Valora tus libros con plantillas personalizadas y comparte tus opiniones con amigos.</p>
        <picture>
          <source srcSet={MainImageLower} media="(max-width: 768px)" type="image/webp" />
          <source srcSet={MainImage} media="(min-width: 769px)" type="image/webp" />
          <img 
            src={MainImage} 
            alt="Imagen principal de InkPal" 
            title="InkPal, gestión de lecturas"
            loading="lazy" 
            className="main-image"
          />
        </picture>
        <div className='radial bottom'></div>
      </div>
      <div className='full-height-darker content'>
        <h2>Interfaz Moderna y Atractiva</h2>
        <p>Descubre la belleza de nuestra interfaz, diseñada para hacer que tu experiencia de gestión de lecturas sea más placentera y eficiente. Explora nuestras funciones intuitivas y personalizables para disfrutar al máximo de tus momentos de lectura.</p>
        <div className='floating-info'>
          <h3>Diseño Intuitivo</h3>
          <p> - </p>
          <h3>Personalización Visual</h3>
        </div>
          <img src={ScreenShot} title='InkPal página inicial' alt="Ink pal app libros" loading='lazy' width={877} height={702}/>
      </div>
      <div className='full-height-lighter content'>
        <h2>Valoraciones Personalizadas con Plantillas</h2>
        <p>Crea valoraciones únicas y detalladas para cada libro con nuestras plantillas personalizadas. Expresa tus opiniones de manera completa y organizada, y comparte tus análisis con otros lectores. Transforma tu experiencia de lectura en una aventura aún más enriquecedora.</p>
        <div className='floating-info'>
          <h3>Selecciona tus Plantillas Favoritas</h3>
          <p> - </p>
          <h3>Guarda y Comparte tus Valoraciones</h3>
        </div>
          <img src={ScreenShotRatingES} title='Valoraciones personalizadas InkPal' alt="Valorar libros plantillas" loading='lazy' width={877} height={702}/>
      </div>
      <div className='full-height-darker content'>
        <h2>Gestión de Lecturas Eficiente</h2>
        <p>Organiza tu biblioteca de lecturas de manera eficiente y sencilla con nuestras herramientas de gestión avanzadas. Sigue tu progreso de lectura, añade notas y etiquetas, y mantén un registro completo de tus libros favoritos. Simplifica tu vida de lector con InkPal.</p>
        <div className='floating-info'>
          <h3>Añade y Organiza tus Libros</h3>
          <p> - </p>
          <h3>Actualiza tu Progreso de Lectura</h3>
        </div>
          <img src={ScreenShotGestionES} title='App gestor de libros' alt="Gestor de lecturas app" loading='lazy' width={877} height={702}/>
      </div>
      <div className='full-height-lighter content'>
        <h2>Conecta con tus Amigos</h2>
        <p>Explora la dimensión social de la lectura al conectar con amigos y compartir tus experiencias literarias. Descubre nuevos títulos recomendados por tus contactos, ve lo que leen las peronas que sigues y disfruta de una comunidad apasionada por los libros.</p>
        <div className='floating-info'>
          <h3>Busca y Añade Amigos</h3>
          <p> - </p>
          <h3>Comparte Recomendaciones de Lectura</h3>
        </div>
          <img src={ScreenShotFriendsES} title='Compartir libros con amigos' alt="App lectura amigos" loading='lazy' width={877} height={702}/>
      </div>
      <div className='full-height-darker content'>
        <h2>Recomendaciones Personalizadas</h2>
        <p>Déjate sorprender por nuestras recomendaciones personalizadas, basadas en tus gustos y preferencias de lectura. Nuestros algoritmos inteligentes te ayudarán a descubrir nuevos libros que se adapten a tus intereses, ampliando así tu horizonte literario. Sumérgete en un mundo de lecturas fascinantes con InkPal.</p>
        <div className='floating-info'>
          <h3>Algoritmos de Recomendación Inteligentes</h3>
          <p> - </p>
          <h3>Explora Nuevas Lecturas</h3>
        </div>
          <img src={ScreenShotRecomendacionES} title='Libros recomendados leer' alt="Recomendación de libros IA" loading='lazy' width={877} height={702}/>
      </div>
      <div id="contact">
        <p className='contact-title'>Contactanos</p>
        <p className='contact-subtitle'>¿Tienes dudas, peticiones o has encontrado algún error? No dudes en contactarnos y contarnoslo.</p>
        <p className='email'>support@inkpalbooks.com</p>
        <a href='mailto:support@inkpalbooks.com' target='_blank' rel="nofollow noreferrer noopener">
          ENVIAR CORREO
        </a>
      </div>
    </PageTemplate>
  )
}

export default HomePageEs