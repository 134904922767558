import { useEffect } from "react";
import thumbnail from '../Images/thumbnail.png';
import { useTranslation } from "react-i18next";
import Seo from "../components/Seo";

function Groups() {

  const {t} = useTranslation();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const groupId = queryParams.get('g');

    //window.location.replace(`inkpalbooks://friend/${friend}`);
    const url = 'inkpalbooks://group/' + groupId;
    window.location.href = url;
    //window.open(`inkpalbooks://friend/0391b2e8-390d-42e1-b12e-7ba5fd499235`);

    // Establece un tiempo de espera (por ejemplo, 2 segundos)
    const timeout = setTimeout(() => {
      // Redirige al usuario a la tienda de aplicaciones si la aplicación no se abre
      //window.location.href = 'https://url-de-tu-tienda-de-aplicaciones';
    }, 2000); // 2000 milisegundos = 2 segundos

    // Limpia el tiempo de espera si la aplicación se abre antes del tiempo de espera
    window.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        clearTimeout(timeout);
      }
    });

    // Limpia el tiempo de espera al desmontar el componente
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="App">
      <Seo 
        title={t("homeSeoTitle")} 
        description={t("homeSeoDescription")} 
        ogTitle={t("homeOgTitle")} 
        ogDescription={t("homeOgDescription")} 
        type="profile" 
        url="https://www.inkpalbooks.com/groups" 
        thumbnail={thumbnail}/>
    </div>
  );
}

export default Groups